import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"
import Testimonials from "../../components/testimonials"


class WebDesignPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Web Design Bournemouth" />
        <HeroAlt 
          image="/first-wealth.jpg"
          title="Website Design"
          subtitle="Old Salt was designed to deliver value and exceptional results for clients of all sizes. We're driven by bringing ideas to life and working with great people."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Helping you to tell your story</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Good website design isn’t just about making something look pretty it also has to be effective. We have been designing websites for nearly 15 years and use the latest design tools to create something that looks great and is effective in achieving your goals.</p>
                <p>A key factor when designing a website is to seriously consider the user experience and how easy and intuitive the website is to use. We believe that a website’s journey doesn’t end when you launch and we take an approach of continually monitoring how customers are using your website, identify areas that are causing friction and monitor these changes to continually improve your website’s design and its effectiveness.</p>
                <Spacer space="2" />    
                <Link to="/contact" className="button">Start your project</Link>
                <Spacer space="2" />    
              </div>              
            </div>
          </div>
        </div>

        <div className="row align-middle shrink-width gm-top--1 gm-bottom--1">
          <div className="column small-12 medium-6 medium-text-left text-center">
            <h3 className="no-margin-bottom button-title">Our Work</h3>
            <p className="gm-bottom--1">Below is a selection of some of the work that we've produced here at Old Salt.</p>
          </div>
          <div className="column small-12 medium-6 text-center medium-text-right">
            <Link to="/work" className="button no-margin-bottom">View work</Link>
          </div>
        </div>
        <WorkGrid>
          <ImageGridItem width={5} image={ '/dbm.jpg' } subtitle="WordPress" title="DBM Group" link="https://www.dbmgroup.com/" showLink="true" />
          <ImageGridItem width={7} image={ '/surfdurt.jpg' } subtitle="Shopify" title="SurfDurt" link="https://www.surfdurt.com/" />
          <ImageGridItem width={7} image={ '/feria.jpg' } subtitle="WordPress" title="Feria Urbanism" link="https://feria-urbanism.com/" showLink="true" />
          <ImageGridItem width={5} image={ '/first-wealth.jpg' } subtitle="WordPress" title="First Wealth" link="https://www.firstwealth.co.uk/" showLink="true" />
        </WorkGrid>   

        <Spacer space="4" />

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>What’s the difference between a web designer and a web developer?</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>A web designer is a graphic artist who is responsible for designing the layout, user experience, and visual appearance of a website. A web developer is someone who writes the code to bring the web designs to life.</p>
                <p>At Old Salt we specialise primarily in <Link className="underline-light" to='/services/website-development-bournemouth'>website development</Link> but web design is also a key factor in our process.</p>
                <Spacer space="3" />    
              </div>              
            </div>
          </div>
        </div>


        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get in touch</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="website-development-bournemouth" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <Spacer space="2" />    


        <div className="row shrink-width align-center">
          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Why use a freelancer over a design agency?</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Using a freelance designer instead of an agency provides numerous benefits. I manage the timeline, speak with you directly and design the website. This means that there is no chain of command, there is no chance of requests and intentions getting lost in translation.</p>
                <p>You'll work with me from start to finish and we'll work closely to bring the project to completion.</p>
                <Spacer space="2" />    
                <Link to="/contact" className="button">Get in touch</Link>
                <Spacer space="2" />
              </div>
            </div>
          </div>
        </div>

        <Spacer space="4" />    

        <Testimonials />
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WebDesignPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`